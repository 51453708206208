import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import { animateCloseButton } from '../animate-close-button';
import { animateProgressBar } from '../animate-progress-bar';
import { SmoothScroll } from '../smooth-scroll';
import { Div, Field } from '..';

type AnimateFormModal = {
  modal: Div;
  contentScreen: Element;
  successScreen: Element;
  openButtons: Element[];
  closeButton: Element;
  fields: Field[];
  errorMessage: Div;
  progressBar: Div;
  resetAnimations: () => void;
  scrollContainer: Div;
  isTouchScreen: boolean;
  // isSafari: boolean;
};

export const animateFormModal = ({
  modal,
  contentScreen,
  successScreen,
  openButtons,
  closeButton,
  fields,
  errorMessage,
  progressBar,
  resetAnimations,
  scrollContainer,
  isTouchScreen,
}: // isSafari,
AnimateFormModal) => {
  let scrollY = 0;

  if (isTouchScreen) modal.classList.add('modal_is-touch');

  animateCloseButton({ button: closeButton });

  const openModal = () => {
    scrollY = SmoothScroll.scrollY;
    const timeline = gsap.timeline({
      defaults: { ease: Power3.easeInOut, duration: 1 },
    });

    new SmoothScroll({
      scrollContainer: modal.lastElementChild as Div,
    });

    if (isTouchScreen) {
      modal.scrollTo({ top: 0 });
      window.scrollTo({ top: scrollY });

      disablePageScroll(modal);
    }

    timeline
      .set(modal, { opacity: 0, zIndex: 100 })
      .set(contentScreen, { opacity: 0, y: '10%' })
      .set(closeButton, { opacity: 0, rotate: -90 })
      .to(modal, { opacity: 1, duration: 1 })
      .to(contentScreen, { opacity: 1, y: 0 })
      .to(closeButton, { opacity: 1, rotate: 0 }, '-=0.5')
      .add(
        animateProgressBar({ progressBar, duration: timeline.duration() }),
        `-=${timeline.duration()}`,
      );
  };

  const closeModal = () => {
    const timeline = gsap.timeline({
      defaults: { ease: Power3.easeInOut, duration: 1 },
    });

    new SmoothScroll({ scrollContainer, initPosition: { x: 0, y: scrollY } });

    if (isTouchScreen) window.scrollTo({ top: scrollY });

    timeline
      .to(modal, { opacity: 0 })
      .set(modal, { zIndex: -100 })
      .then(() => {
        resetAnimations();

        if (isTouchScreen) enablePageScroll(modal);
        modal.scrollTo({ top: 0 });

        fields.forEach(field => (field.value = ''));

        gsap.set([contentScreen, successScreen, errorMessage], {
          clearProps: 'all',
        });
      });
  };

  openButtons.forEach(button => button.addEventListener('click', openModal));

  closeButton.addEventListener('click', closeModal);
};
