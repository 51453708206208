import { gsap } from 'gsap';
import Power3 from 'gsap/CustomEase';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { updateCursorPosition } from './handle-custom-cursor';
import { Div } from '.';

gsap.registerPlugin(Draggable, InertiaPlugin);

type HandleTestimonialsCarouselProps = {
  wrapper: Element;
  carousel: Div;
  slides: Div[];
  bullets: Element[];
  cursor: Div;
};

export const handleTestimonialsCarousel = ({
  wrapper,
  carousel,
  slides,
  bullets,
  cursor,
}: HandleTestimonialsCarouselProps) => {
  let offsets: number[] = [];
  let [index, prevIndex] = [0, 0];
  let dir = 0;

  slides.forEach((slide, i) => gsap.set(slide, { opacity: index === i ? 1 : 0 }));

  const calcOffsets = () => {
    offsets = [];
    slides.forEach(slide => offsets.push(-slide.offsetLeft));
    gsap.set(carousel, { x: offsets[index] });
  };

  ScrollTrigger.addEventListener('refreshInit', calcOffsets);

  function onDragStart(this: Draggable.Vars, e: any) {
    e.preventDefault();

    const { startX, endX } = this;
    const deltaX = startX - endX;
    dir = deltaX > 0 ? -1 : 1;

    prevIndex = index;
    index =
      (index === 0 && deltaX < 0) || (index === slides.length - 1 && deltaX >= 0)
        ? index
        : deltaX > 0
        ? index + 1
        : index - 1;

    gsap.to(bullets[index], { opacity: 1 });
    gsap.to(bullets[prevIndex], { opacity: 0.2 });
  }

  function onDrag(this: Draggable.Vars) {
    const { clientX, clientY } = this.pointerEvent;

    updateCursorPosition({ cursor, x: clientX, y: clientY });
  }

  const timeline = gsap.timeline({
    defaults: { duration: 0.5, ease: Power3.easeInOut },
  });

  function onDragEnd(this: Draggable.Vars) {
    if (prevIndex !== index) {
      timeline
        .set(slides[prevIndex], { x: 0 })
        .set(slides[index], { x: dir === 1 ? '-15%' : '15%' })
        .to(slides[prevIndex], { x: dir === 1 ? '15%' : '-15%', opacity: 0 })
        .set(carousel, { x: offsets[index] })
        .to(slides[index], { x: 0, opacity: 1 });
    }
  }

  Draggable.create(carousel, {
    bounds: wrapper,
    type: 'x',
    // cursor: "url('images/drag-cursor.svg') 44 14, auto",
    // activeCursor: "url('images/dragging-cursor.svg') 44 14, auto",
    edgeResistance: 1,
    dragResistance: 0.95,
    throwResistance: 4000,
    throwProps: true,
    inertia: true,
    allowNativeTouchScrolling: true,
    zIndexBoost: false,
    overshootTolerance: 0,
    onDragStart,
    onDrag,
    onDragEnd,
  });
};
