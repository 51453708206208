const waitTiming = () =>
  new Promise<any>(resolve => {
    const handler = () => {
      const timing = performance.getEntriesByType('navigation')[0].toJSON();
      timing.start = performance.timing.requestStart;
      delete timing.serverTiming;

      if (timing.duration > 0) {
        clearInterval(interval);
        resolve(timing);
      }
    };

    const interval = setInterval(handler, 100);
  });

// * source: https://github.com/alex-vv/page-load-time/blob/master/src/performance.js
export async function getPageLoadingTime() {
  // TODO: fix on safari
  if (!performance.mark || !performance.getEntriesByType('navigation')[0]) {
    return 3;
  }

  const timing = await waitTiming();

  // fetchStart sometimes negative in FF, make an adjustment based on fetchStart
  const adjustment = timing.fetchStart < 0 ? -timing.fetchStart : 0;
  [
    'domainLookupStart',
    'domainLookupEnd',
    'connectStart',
    'connectEnd',
    'requestStart',
    'responseStart',
    'responseEnd',
    'domComplete',
    'domInteractive',
    'domContentLoadedEventStart',
    'domContentLoadedEventEnd',
    'loadEventStart',
    'loadEventEnd',
    'duration',
  ].forEach(i => (timing[i] += adjustment));

  // we have only 4 chars in our disposal including decimal point
  const duration = timing.duration / 1000;
  const precision = duration >= 100 ? 0 : duration >= 10 ? 1 : 2;
  const time = Number(duration.toFixed(precision).substring(0, 4));

  return time;
}
