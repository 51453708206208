import { throttle } from 'throttle-debounce-ts';

type ImageOnMouseMoveProps = {
  wrapper: Element;
  images: Element[];
  inViewClass: string;
};

export const changeImageOnMouseMove = ({ wrapper, images, inViewClass }: ImageOnMouseMoveProps) => {
  let i = 0;

  const changeImg = () => {
    images[i > 0 ? i - 1 : images.length - 1].classList.remove(inViewClass);

    if (i === images.length) i = 0;

    images[i].classList.add(inViewClass);
    i++;
  };

  let subscribed = false;

  const callThrottle = throttle(400, changeImg);

  const options = { root: document.body, rootMargin: '0px', threshold: 0.1 };
  const observer = new IntersectionObserver(entries => {
    const isVisible = entries[0].isIntersecting;

    if (isVisible && !subscribed) {
      document.addEventListener('wheel', callThrottle);
      subscribed = true;
    } else if (!isVisible && subscribed) {
      document.removeEventListener('wheel', callThrottle);
      subscribed = false;
    }
  }, options);

  observer.observe(wrapper);

  wrapper.addEventListener('mousemove', throttle(400, changeImg));
};
