import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';
import { throttle } from 'throttle-debounce-ts';

import { checkIsDesktop } from './utils';
import { Div } from '.';

type AnimateWorksHoverProps = {
  outerImages: Div[];
  arrowButtons: Element[];
};

export const animateWorksHover = ({ outerImages, arrowButtons }: AnimateWorksHoverProps) => {
  let isDesktop = checkIsDesktop();

  window.addEventListener(
    'resize',
    throttle(100, () => (isDesktop = checkIsDesktop())),
  );

  const timelineConfig = {
    defaults: {
      ease: Power3.easeInOut,
      duration: 0.3,
      delay: 0,
      overwrite: true,
    },
  };

  outerImages.forEach((imageWrapper, index) => {
    const image = imageWrapper.firstElementChild as Element;
    const arrowButton = arrowButtons[index];

    const timeline = gsap.timeline(timelineConfig);

    timeline.set(image, { scale: 1 });

    imageWrapper.addEventListener('mouseenter', () => {
      timeline.to(image, { scale: 1.2 }).to(arrowButton, isDesktop ? { x: 0 } : {}, '-=100%');
    });

    imageWrapper.addEventListener('mouseleave', () => {
      timeline.to(image, { scale: 1 }).to(arrowButton, isDesktop ? { x: -30 } : {}, '-=100%');
    });
  });

  arrowButtons.forEach((arrowButton, i) => {
    const wrapper = arrowButton.parentElement as HTMLElement;
    const image = outerImages[i].firstElementChild as Element;

    const timeline = gsap.timeline(timelineConfig);

    timeline.set(arrowButton, { x: -30 });

    wrapper.addEventListener('mouseenter', () => {
      timeline.to(image, { scale: 1.2 }).to(arrowButton, isDesktop ? { x: 0 } : {}, '-=100%');
    });

    wrapper.addEventListener('mouseleave', () => {
      timeline.to(image, { scale: 1 }).to(arrowButton, isDesktop ? { x: -30 } : {}, '-=100%');
    });
  });
};
