import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

type AnimateFooterLinkHoverProps = {
  element: Element;
  link: Element;
  socialLinks: Element[];
};

export const animateFooterLinksHover = ({
  element,
  link,
  socialLinks,
}: AnimateFooterLinkHoverProps) => {
  const animationConfig = { ease: Power3.easeInOut, duration: 0.3 };

  link.addEventListener('mouseenter', () => {
    element.classList.add('page_hover');
    document.body.classList.add('page_hover');
  });

  link.addEventListener('mouseleave', () => {
    element.classList.remove('page_hover');
    document.body.classList.remove('page_hover');
  });

  socialLinks.forEach(link => {
    link.addEventListener('mouseenter', () => {
      gsap.to(`.${link.classList[0]}`, { opacity: 0.5, ...animationConfig });
      gsap.to(link, { opacity: 1, ...animationConfig });
    });

    link.addEventListener('mouseleave', () => {
      gsap.to(`.${link.classList[0]}`, { opacity: 1, ...animationConfig });
    });
  });
};
