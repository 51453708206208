import { enableBodyScroll } from 'body-scroll-lock';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { animateFormModal } from './contact-form/animate-form-modal';
import {
  animateErrorFormSubmit,
  animateSuccessfulFormSubmit,
} from './contact-form/animate-form-submit';
import { animateAccordions } from './animate-accordions';
import { animateFooterLinksHover } from './animate-footer-links-hover';
import { animateGetInTouchIcon } from './animate-get-in-touch-icon';
import { animateProcessButtons } from './animate-process-buttons';
import { animateScrollDeformation } from './animate-scroll-deformation';
import { animateWorksHover } from './animate-works-hover';
import { changeImageOnMouseMove } from './change-image-on-mouse-move';
import { submitForm } from './contact-form';
import { detectTouchscreen } from './detect-touchscreen';
import { handleCustomCursor } from './handle-custom-cursor';
import { handleDragSlider } from './handle-drag-slider';
import { handleModals } from './handle-modals';
import { handlePreloader } from './handle-preloader';
import { handleTestimonialsCarousel } from './handle-testimonials-carousel';
import { setContainerPadding } from './set-container-padding';
import { SmoothScroll } from './smooth-scroll';
import { setCustomVh } from './utils';

export type Div = HTMLDivElement;
export type Field = HTMLInputElement | HTMLTextAreaElement;
export type ReinitializeSmoothScroll = ({
  initPosition,
}: {
  initPosition: { x: number; y: number };
}) => void;

gsap.registerPlugin(ScrollTrigger);

const root = document.documentElement as HTMLHtmlElement;
const isTouchScreen = detectTouchscreen();
// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isTouchScreen) root.classList.add('touch-screen');
setCustomVh(root);

const page = document.getElementsByClassName('page')[0] as Div;
const viewport = document.getElementsByClassName('viewport')[0] as Div;

const progressBar = document.getElementById('progress-bar') as Div;
const preloaderScreen = document.getElementById('preloader-screen') as Div;
const header = document.getElementById('header') as Div;

const getInTouchIcon = document.getElementsByClassName('sticky-icon__get-in-touch')[0];

const callAnimateScrollDeformation = () =>
  animateScrollDeformation({
    element: '.section',
    scroller: viewport,
    trigger: page,
  });

const preloaderCallback = () => {
  new SmoothScroll({ scrollContainer: viewport });

  animateGetInTouchIcon({
    element: getInTouchIcon,
    scroller: viewport,
    trigger: page,
    offsetTop: page.offsetTop,
  });

  if (!isTouchScreen) callAnimateScrollDeformation();

  enableBodyScroll(viewport);
};

if (progressBar && preloaderScreen) {
  handlePreloader({
    progressBar,
    preloaderScreen,
    header,
    logo: document.getElementsByClassName('preloader-screen__logo')[0] as Div,
    getInTouchIcon,
    scrollContainer: viewport,
    callback: preloaderCallback,
  });
} else {
  preloaderCallback();
}

const mouseMoveAnimationWrapper = document.getElementsByClassName('mouse-move-animation')[0];

if (mouseMoveAnimationWrapper)
  changeImageOnMouseMove({
    wrapper: mouseMoveAnimationWrapper,
    images: Array.from(mouseMoveAnimationWrapper.children),
    inViewClass: 'mouse-move-animation__image_in-view',
  });

const modals = Object.values(document.getElementsByClassName('works__modal'));
const outerModalImages = Object.values(
  document.getElementsByClassName('works__item-image-wrapper'),
) as Div[];
const arrowOpenButtons = Object.values(
  document.getElementsByClassName('works__item-button'),
) as Div[];

const resetAnimations = () => {
  animateGetInTouchIcon({
    element: getInTouchIcon,
    scroller: viewport,
    trigger: page,
    offsetTop: page.offsetTop,
  });

  if (!isTouchScreen) callAnimateScrollDeformation();
};

if (outerModalImages && arrowOpenButtons)
  animateWorksHover({
    outerImages: outerModalImages,
    arrowButtons: arrowOpenButtons,
  });

if (modals)
  handleModals({
    elements: {
      modals,
      headers: Object.values(document.getElementsByClassName('works__modal-header')),
      modalWrappers: Object.values(document.getElementsByClassName('works__modal-wrapper')),
      openButtons: [
        ...(Object.values(document.getElementsByClassName('progress__button')) as Div[]),
        ...arrowOpenButtons,
        ...outerModalImages,
      ],
      closeButtons: Object.values(document.getElementsByClassName('modal__case-close')),
      nextButtons: Object.values(document.getElementsByClassName('modal__footer-next')),

      outerImages: outerModalImages,
      firstImages: Object.values(
        document.getElementsByClassName('modal__image-wrapper_animated'),
      ) as Div[],
      progressBar,
      scrollContainer: viewport,
      progressBars: Object.values(document.getElementsByClassName('modal__progress')),
    },
    pageElements: {
      header,
      getInTouchIcon,
    },
    isTouchScreen,
    resetAnimations,
  });

const customPlusCursor = document.getElementById('custom-plus-cursor') as Div;

if (outerModalImages && customPlusCursor) {
  outerModalImages.forEach(element =>
    handleCustomCursor({ cursor: customPlusCursor, wrapper: element }),
  );
}

const accordions = Object.values(document.getElementsByClassName('accordion'));

if (accordions) animateAccordions({ accordions, isTouchScreen });

const container = document.getElementsByClassName('page__container')[0];

if (container) setContainerPadding(container, root);

const processCarousel = document.getElementsByClassName('process__carousel')[0] as Element;
const processCarouselWrapper = document.getElementsByClassName('process__wrapper')[0] as Div;
const customSliderCursor = document.getElementById('custom-slider-cursor') as Div;

if (processCarousel)
  handleDragSlider({
    wrapper: processCarouselWrapper,
    carousel: processCarousel,
    root,
    slides: Object.values(document.getElementsByClassName('process__item')) as Div[],
    cursor: customSliderCursor,
  });

const processCards = Object.values(document.getElementsByClassName('process__card'));

if (processCards) animateProcessButtons(processCards);

if (customSliderCursor && processCarouselWrapper) {
  handleCustomCursor({
    cursor: customSliderCursor,
    wrapper: processCarouselWrapper,
  });
}

const testimonialsCarousel = document.getElementsByClassName('testimonials__carousel')[0] as Div;
const testimonialsWrapper = document.getElementsByClassName('testimonials__wrapper')[0] as Div;

if (testimonialsCarousel)
  handleTestimonialsCarousel({
    wrapper: testimonialsWrapper,
    carousel: testimonialsCarousel,
    slides: Object.values(document.getElementsByClassName('testimonials__item')) as Div[],
    bullets: Object.values(document.getElementsByClassName('testimonials__bullet')),
    cursor: customSliderCursor,
  });

if (customSliderCursor && testimonialsWrapper) {
  handleCustomCursor({
    cursor: customSliderCursor,
    wrapper: testimonialsWrapper,
  });
}

const footerLink = document.getElementsByClassName('footer__together-button')[0];

if (footerLink)
  animateFooterLinksHover({
    element: page,
    link: footerLink,
    socialLinks: Object.values(document.getElementsByClassName('footer__social-link')),
  });

const formModal = document.getElementById('form-modal') as Div;
const formFields = [
  ...(Object.values(document.getElementsByClassName('form-modal__input')) as HTMLInputElement[]),
  ...(Object.values(
    document.getElementsByClassName('form-modal__textarea'),
  ) as HTMLTextAreaElement[]),
];

const formContentScreen = document.getElementById('form-modal__wrapper') as Element;
const formSuccessScreen = document.getElementById('form-modal__success-message') as Element;
const formErrorMessage = document.getElementsByClassName('form-modal__error-message')[0] as Div;

if (formModal)
  animateFormModal({
    modal: formModal,
    contentScreen: formContentScreen,
    successScreen: formSuccessScreen,
    openButtons: Object.values(document.getElementsByClassName('open-form-modal')),
    closeButton: document.getElementsByClassName('modal__form-close')[0],
    fields: formFields,
    errorMessage: formErrorMessage,
    progressBar,
    resetAnimations,
    scrollContainer: viewport,
    isTouchScreen,
    // isSafari,
  });

const form = document.getElementById('form-modal__form');

if (form)
  submitForm({
    form,
    fields: formFields,
    callbacks: {
      error: () => animateErrorFormSubmit({ errorMessage: formErrorMessage }),
      success: () =>
        animateSuccessfulFormSubmit({
          fields: formFields,
          contentScreen: formContentScreen,
          successScreen: formSuccessScreen,
          errorMessage: formErrorMessage,
        }),
    },
  });
