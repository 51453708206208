import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

type AnimateAccordionsProps = {
  accordions: Element[];
  isTouchScreen: boolean;
};

export const animateAccordions = ({ accordions, isTouchScreen }: AnimateAccordionsProps) => {
  gsap.defaults({ ease: Power3.easeInOut });

  accordions.forEach((accordion: Element, index: number) => {
    let isOpen = index === 0 ? true : false;

    const title = accordion.getElementsByClassName('accordion__title-wrapper')[0];
    const content = accordion.getElementsByClassName('accordion__content')[0];
    const icon = accordion.getElementsByClassName('accordion__icon')[0];
    const progressLine = accordion.getElementsByClassName('accordion__progress-line')[0];

    const applyHover = () => {
      gsap.to(progressLine, { scaleX: 1 });
      !isOpen && gsap.to(icon, { rotate: 90 });
    };

    const removeHover = () => {
      gsap.to(progressLine, { scaleX: 0 });
      !isOpen && gsap.to(icon, { rotate: 0 });
    };

    accordion.addEventListener('mouseenter', () => !isTouchScreen && applyHover());
    accordion.addEventListener('mouseleave', () => !isTouchScreen && removeHover());

    gsap.set(content, { height: isOpen ? 'auto' : '0px' });
    gsap.set(icon.lastElementChild, { rotate: isOpen ? 90 : 0 });

    title.addEventListener('click', () => {
      isOpen = !isOpen;

      gsap.to(content, { height: isOpen ? 'auto' : '0px' });
      gsap.to(icon, { rotate: isOpen ? 180 : 90 });
      gsap.to(icon.lastElementChild, { rotate: isOpen ? 90 : 0 });
    });
  });
};
