import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { clearScrollTrigger } from './utils';
import { Div } from '.';

type AnimateGetInTouchIconProps = {
  element: Element;
  scroller: Element | Div;
  trigger: Div;
  offsetTop: number;
};

export const animateGetInTouchIcon = ({
  element,
  scroller,
  trigger,
  offsetTop,
}: AnimateGetInTouchIconProps) => {
  clearScrollTrigger('animateGetInTouchIcon');

  ScrollTrigger.saveStyles(element);

  gsap.to(element, {
    scrollTrigger: {
      scroller,
      trigger,
      start: `top-=${offsetTop}px top`,
      end: 'bottom',
      scrub: true,
      invalidateOnRefresh: true,
      id: 'animateGetInTouchIcon',
    },
    rotate: window.innerHeight * 2,
    ease: 'none',
  });

  const hover = gsap.to(element.firstElementChild, {
    scale: 1.1,
    duration: 0.2,
    delay: 0,
    ease: 'easeInOut',
  });

  hover.pause();

  element.addEventListener('mouseenter', () => hover.play());
  element.addEventListener('mouseleave', () => hover.reverse());
};
