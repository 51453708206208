import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

export const animateProcessButtons = (cards: Element[]) => {
  gsap.defaults({ ease: Power3.easeInOut });

  cards.forEach(card => {
    const button = card.getElementsByClassName('progress__button')[0];
    const icon = card.getElementsByClassName('progress__icon')[0];
    const progress = card.getElementsByClassName('progress__hover-line')[0];

    if (button && icon && progress) {
      const timeline = gsap.timeline();

      const hover = timeline.to(progress, { scaleX: 1 }).to(icon, { rotate: 45 }, '<');

      hover.pause();

      button.addEventListener('mouseenter', () => hover.play());
      button.addEventListener('mouseleave', () => hover.reverse());
    }
  });
};
