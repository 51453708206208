import { disableBodyScroll } from 'body-scroll-lock';
import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

import { Div } from '..';
import { getPageLoadingTime } from './get-page-loading-time';

type HandlePreloaderProps = {
  progressBar: Div;
  preloaderScreen: Div;
  header: Div;
  logo: Div;
  scrollContainer: Div;
  getInTouchIcon: Element;
  callback: () => void;
};

export const handlePreloader = ({
  progressBar,
  preloaderScreen,
  header,
  logo,
  scrollContainer,
  getInTouchIcon,
  callback,
}: HandlePreloaderProps) => {
  const defaultDuration = 0.5;
  const timeline = gsap.timeline({
    defaults: { ease: Power3.easeInOut, duration: defaultDuration },
  });

  disableBodyScroll(scrollContainer);

  const animateLoading = (progressDuration: number) => {
    const totalDuration = defaultDuration * 4;

    timeline
      .set([scrollContainer, header], { opacity: 0, y: 200 })
      .set(scrollContainer.firstElementChild, { opacity: 0 })
      .set(logo, { opacity: 0, y: '100%' })
      .set(getInTouchIcon.parentElement, { opacity: 0 })
      .set(progressBar, { scaleX: 0, y: 0, opacity: 1 })
      .to(logo, { opacity: 1, y: 0 })
      .to(progressBar, {
        scaleX: 1,
        y: 0,
        duration: () => {
          const duration = Math.abs(totalDuration - progressDuration);

          return duration > 1 ? duration : 1;
        },
      })
      .to(logo, { opacity: 0, y: '50%' })
      .to(progressBar, { y: '100%' })
      .to(preloaderScreen, { opacity: 0 }, '<')
      .to([scrollContainer, header], { opacity: 1, y: 0 })
      .to(scrollContainer.firstElementChild, { opacity: 1 }, '<')
      .set(progressBar, { opacity: 0 })
      .set(preloaderScreen, { zIndex: -100 })
      .set([scrollContainer, scrollContainer.firstElementChild, header], {
        clearProps: 'all',
      })
      .to(getInTouchIcon.parentElement, { opacity: 1, duration: 0.2 });
  };

  getPageLoadingTime()
    .then(animateLoading)
    .catch(() => animateLoading(3))
    .finally(callback);
};
