import gsap from 'gsap';
import { Power3 } from 'gsap/all';

import { Div, Field } from '..';

const animationConfig = { ease: Power3.easeInOut, duration: 0.5 };

type AnimateSuccessfulFormSubmitProps = {
  fields: Field[];
  contentScreen: Element;
  successScreen: Element;
  errorMessage: Div;
};

export const animateSuccessfulFormSubmit = ({
  fields,
  contentScreen,
  successScreen,
  errorMessage,
}: AnimateSuccessfulFormSubmitProps) => {
  const timeline = gsap.timeline({ defaults: animationConfig });

  timeline
    .set(successScreen, { opacity: 0, y: '10%', visibility: 'visible' })
    .to(contentScreen, { opacity: 0, y: '10%' })
    .set(contentScreen, { display: 'none', zIndex: -2 })
    .to(successScreen, { opacity: 1, y: 0 });

  fields.forEach(input => (input.value = ''));
  timeline.set(errorMessage, { display: 'none' });
};

export const animateErrorFormSubmit = ({ errorMessage }: { errorMessage: Div }) => {
  const timeline = gsap.timeline({ defaults: animationConfig });

  timeline
    .set(errorMessage, { opacity: 0, y: 32, display: 'block' })
    .to(errorMessage, { opacity: 1, y: 0 });

  // errorMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
};
