// * source: https://codepen.io/ShehzadAsif/pen/dypyRoQ
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';

import { detectTouchscreen } from './detect-touchscreen';
import { Div } from '.';

type SmoothScrollProps = {
  scrollContainer: Div;
  initPosition?: { x: number; y: number };
  // id?: string;
};

type LocomotiveScrollType = {
  scroll: { instance: { scroll: { y: number; x: number } } };
  destroy(): void;
  on(event: 'scroll' | 'call', callback: (props: any) => void): void;
  stop(): void;
};

// TODO: find out why reloadOnContextChange: true not working when window size is increased
export class SmoothScroll {
  static instance: SmoothScroll;

  static locomotiveScroll: LocomotiveScrollType;

  constructor(props: SmoothScrollProps) {
    if (SmoothScroll.instance) {
      this.destroySmoothScroll();
      SmoothScroll.locomotiveScroll = this.initSmoothScroll(props);
      return SmoothScroll.instance;
    }

    SmoothScroll.locomotiveScroll = this.initSmoothScroll(props);
    return (SmoothScroll.instance = this);
  }

  static get scrollY() {
    const smoothScrollY = SmoothScroll.locomotiveScroll.scroll.instance.scroll.y;

    return smoothScrollY > 0 ? smoothScrollY : window.scrollY;
  }

  static on(event: 'scroll' | 'call', callback: (props: any) => void) {
    return SmoothScroll.locomotiveScroll.on(event, callback);
  }

  static stop() {
    return SmoothScroll.locomotiveScroll.stop();
  }

  private destroySmoothScroll() {
    SmoothScroll.locomotiveScroll.destroy();
  }

  private initSmoothScroll({
    scrollContainer,
    initPosition = { x: 0, y: 0 },
  }: SmoothScrollProps): LocomotiveScrollType {
    const smoothScroll = new LocomotiveScroll({
      el: scrollContainer,
      smooth: !detectTouchscreen(),
      initPosition,
      tablet: { smooth: false, breakpoint: 0 },
      // smartphone: { smooth: false },
      // reloadOnContextChange: true,
      // resetNativeScroll: true,
      // getSpeed: true,
      // lerp: 0.4,
    });

    smoothScroll.on('scroll', ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(scrollContainer, {
      scrollTop(value) {
        return arguments.length
          ? smoothScroll.scrollTo(value, 0, 0)
          : smoothScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      // pinType: scrollContainer.style.transform ? 'transform' : 'fixed',
    });

    ScrollTrigger.addEventListener('refresh', () => {
      smoothScroll.update();
    });

    ScrollTrigger.refresh();

    return smoothScroll;
  }
}
