import ScrollTrigger from 'gsap/ScrollTrigger';
import { throttle } from 'throttle-debounce-ts';

export const checkIsDesktop = () => window.matchMedia('(min-width: 768px)').matches;

export const setCustomVh = (root: HTMLHtmlElement) => {
  const setVh = () => root.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');

  setVh();
  setTimeout(setVh, 10);
  window.addEventListener('resize', throttle(30, setVh));
};

export const clearScrollTrigger = (id: string) => {
  ScrollTrigger.getAll().forEach(instance => {
    if (instance.vars.id === id) instance.kill();
  });
};
