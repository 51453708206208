import { gsap } from 'gsap';
import Power3 from 'gsap/CustomEase';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { updateCursorPosition } from './handle-custom-cursor';
import { Div } from '.';

gsap.registerPlugin(Draggable, InertiaPlugin);

type HandleDragSliderProps = {
  wrapper: Element;
  carousel: Element;
  root: HTMLHtmlElement;
  slides: Div[];
  cursor: Div;
};

export const handleDragSlider = ({
  wrapper,
  carousel,
  root,
  slides,
  cursor,
}: HandleDragSliderProps) => {
  const ease = Power3.easeInOut;
  let [offsets, positionX]: number[][] = [[], []];
  let index = 0;

  const calcOffsets = () => {
    [offsets, positionX] = [[], []];

    const containerPadding = parseInt(root.style.getPropertyValue('--container-padding'));
    const lastOffset = window.innerWidth - (slides[index].clientWidth + containerPadding);
    const centerOffset = window.innerWidth / 2 - slides[index].clientWidth / 2;

    slides.forEach((slide, i) => {
      const offset = -slide.offsetLeft;
      offsets.push(offset);

      positionX.push(
        i === 0
          ? offset + containerPadding
          : i !== slides.length - 1
          ? offset + centerOffset
          : offset + lastOffset,
      );
    });

    gsap.set(carousel, { x: positionX[index] });
  };

  ScrollTrigger.addEventListener('refreshInit', calcOffsets);

  function onDrag(this: Draggable.Vars) {
    const { clientX, clientY } = this.pointerEvent;

    updateCursorPosition({ cursor, x: clientX, y: clientY });

    gsap.to(carousel, { ease, duration: 0.1 });
  }

  function onDragEnd(this: Draggable.Vars) {
    const { startX, endX } = this;
    const duration = this.tween.duration();
    const deltaX = startX - endX;

    gsap.to(carousel, { duration, ease });

    index =
      (index === 0 && deltaX <= 0) || (index === slides.length - 1 && deltaX > 0)
        ? index
        : deltaX > 0
        ? index + 1
        : index - 1;

    gsap.to(carousel, { x: positionX[index], duration, ease });
  }

  Draggable.create(carousel, {
    bounds: wrapper,
    type: 'x',
    edgeResistance: 0.1,
    dragResistance: 0.4,
    throwResistance: 4000,
    throwProps: true,
    inertia: { duration: { overshoot: 0.5, max: 4, min: 0.5 } },
    allowNativeTouchScrolling: true,
    zIndexBoost: false,
    overshootTolerance: 0,
    onDrag,
    onDragEnd,
  });
};
