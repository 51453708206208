import { Field } from '..';
import { FormCallbacks, sendForm } from './send-form';

type SubmitFormProps = {
  form: Element;
  fields: Field[];
} & FormCallbacks;

export const submitForm = ({ form, fields, callbacks }: SubmitFormProps) => {
  form.addEventListener('submit', e => {
    e.preventDefault();

    const data = fields.reduce((acc, field) => {
      if (field.name) acc[field.name] = field.value;

      return acc;
    }, {} as any);

    sendForm({ body: { ...data }, callbacks });
  });
};
