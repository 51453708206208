import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

export const animateCloseButton = ({ button }: { button: Element }) => {
  gsap.defaults({ ease: Power3.easeInOut, duration: 0.5 });

  button.addEventListener('mouseenter', () => gsap.to(button, { rotate: 90 }));

  button.addEventListener('mouseleave', () => gsap.to(button, { rotate: 0 }));
};
