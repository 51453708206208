import { stringify } from 'qs';

import { getGAClientId } from './get-ga-client-id';

export type FormCallbacks = {
  callbacks: {
    error: () => void;
    success: () => void;
  };
};

type SendFormProps = {
  body: { name: string; email: string; message: string };
} & FormCallbacks;

export const sendForm = async ({ body, callbacks }: SendFormProps) => {
  await fetch(`/api/get-in-touch.php`, {
    method: 'post',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    body: stringify({
      ...body,
      domain: 'https://shakuro.com/',
      message: body.message || 'empty message',
      clientIdGA: getGAClientId(),
    }),
  })
    .then(async res => {
      if (!res.ok) throw res;

      callbacks.success();
    })
    .catch(error => {
      callbacks.error();

      console.log('Error status:', error);
    });
};
