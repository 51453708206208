/* eslint-disable max-lines */
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { gsap } from 'gsap';
// GSDevTools
import { Power3 } from 'gsap/all';
import CustomEase from 'gsap/CustomEase';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { animateCloseButton } from './animate-close-button';
import { animateProgressBar } from './animate-progress-bar';
import { animateScrollDeformation, deformationInfo } from './animate-scroll-deformation';
import { SmoothScroll } from './smooth-scroll';
import { clearScrollTrigger } from './utils';
import { Div } from '.';

// gsap.registerPlugin(GSDevTools);
gsap.registerPlugin(CustomEase);

CustomEase.create('customEase', 'M0,0 C0.75,0 0.25,1 1,1 ');

type HandleModalProps = {
  elements: {
    modals: Element[];
    headers: Element[];
    modalWrappers: Element[];
    openButtons: Div[];
    closeButtons: Element[];
    nextButtons: Element[];
    outerImages: Div[];
    firstImages: Div[];
    progressBar: Div;
    scrollContainer: Div;
    progressBars: Element[];
  };
  pageElements: {
    header: Div;
    getInTouchIcon: Element;
  };
  isTouchScreen: boolean;
  resetAnimations: () => void;
};

type AnimationTrigger = 'image' | 'bottom' | null;

export const handleModals = ({
  elements: {
    modals,
    headers,
    modalWrappers,
    openButtons,
    closeButtons,
    nextButtons,
    outerImages,
    firstImages,
    progressBar,
    scrollContainer,
    progressBars,
  },
  pageElements: { header, getInTouchIcon },
  isTouchScreen,
  resetAnimations,
}: HandleModalProps) => {
  // gsap.defaults({ ease: Power3.easeInOut });
  gsap.defaults({ ease: 'customEase' });

  let currentIndex = 0;
  let animationTrigger: AnimationTrigger = null;
  let scrollY = 0;

  if (isTouchScreen) modals.forEach(modal => modal.classList.add('modal_is-touch'));

  const openNextModal = async (prevIndex: number = currentIndex) => {
    animationTrigger = 'bottom';
    const validIndex = (prevIndex + 1) % modals.length;

    currentIndex = validIndex;
    await openModal({ index: validIndex, isOpenNextModal: true });
    closeModal(prevIndex);
  };

  type OpenNextModalProps = { index: number; isOpenNextModal?: boolean };

  const openModal = ({ index, isOpenNextModal = false }: OpenNextModalProps) =>
    gsap.set('.section', { clearProps: 'all' }).then(async () => {
      // await deformationInfo.waitEndOfDeformation();
      scrollY = isOpenNextModal ? scrollY : Math.round(SmoothScroll.scrollY);

      const prevIndex = index === 0 ? modals.length - 1 : index - 1;

      const {
        offsetTop: originalImgTop,
        offsetLeft: originalImgLeft,
        clientWidth: originalImgWidth,
        // clientHeight: originalImgHeight,
      } = outerImages[index];

      const { clientHeight: originalImgHeight } = outerImages[index].parentElement as Div;

      const {
        offsetTop: modalImgTop,
        offsetLeft: modalImgLeft,
        clientWidth: modalImgWidth,
        clientHeight: modalImgHeight,
      } = firstImages[index];

      const [
        modal,
        outerImage,
        modalImage,
        modalHeader,
        modalWrapper,
        closeButton,
        modalProgressBar,
      ] = [
        modals[index],
        outerImages[index],
        firstImages[index],
        headers[index],
        modalWrappers[index],
        closeButtons[index],
        progressBars[index],
      ];

      const outerImageInner = outerImage.firstElementChild as Div;
      const outerImageWrapper = outerImage.parentElement as Div;
      const modalImageWrapper = modalImage.parentElement as Div;
      const scrollContainer = modal.lastElementChild as Div;

      gsap.set(modal, { opacity: 1 });
      gsap.set(closeButton, { opacity: 0, rotate: -90 });

      const animateShowCloseButton = () => gsap.to(closeButton, { opacity: 1, rotate: 0 });

      new SmoothScroll({ scrollContainer });

      const callAnimateScrollDeformation = () => {
        animateScrollDeformation({
          element: '.works__modal-wrapper',
          scroller: scrollContainer,
          trigger: modal,
          endTrigger: modalProgressBar,
        });
      };

      const animateModalProgressBar = () => {
        gsap.set(modalProgressBar, { scaleX: 0 });
        gsap.to(modalProgressBar, {
          scrollTrigger: {
            scroller: scrollContainer,
            trigger: modalWrapper.firstElementChild as Div,
            scrub: true,
            pin: true,
            start: 'bottom bottom',
            end: '+=100%',
            id: 'animateEndScrollProgressBar',
            onToggle: ({ progress }) => {
              if (progress === 1) {
                clearScrollTrigger('animateScrollDeformation');
                openNextModal(index);

                gsap.set(modalProgressBar, { scaleX: 1 });
              }
            },
          },
          scaleX: 1,
          transformOrigin: 'left center',
          ease: Power3.easeInOut,
        });

        ScrollTrigger.refresh();
      };

      if (!isTouchScreen) {
        callAnimateScrollDeformation();

        clearScrollTrigger('animateEndScrollProgressBar');

        animateModalProgressBar();
      }

      const hideHeader = () =>
        gsap
          .timeline()
          .to(getInTouchIcon.parentElement, { opacity: 0, duration: 0.2 })
          .to(header, { y: '-100%', duration: 0.3 });

      const animateImageModal = () =>
        new Promise(async resolve => {
          await deformationInfo.waitEndOfDeformation();

          // scrollY = isOpenNextModal
          //   ? scrollY
          //   : Math.round(SmoothScroll.scrollY);

          // new SmoothScroll({ scrollContainer });

          const timeline = gsap.timeline({
            defaults: { ease: 'customEase', duration: 1 },
            id: 'animateImageModal',
          });

          timeline
            .set(outerImageWrapper, { height: originalImgHeight })
            .set(outerImage, {
              position: 'absolute',
              cursor: 'auto',
              top: originalImgTop,
              left: originalImgLeft,
              width: originalImgWidth,
              height: originalImgHeight,
              zIndex: 90,
            })
            .set(modalImageWrapper, {
              width: modalImgWidth,
              height: modalImgHeight,
            });

          if (isTouchScreen) {
            window.scrollTo({ top: scrollY });
            disableBodyScroll(modal);
          }

          timeline
            .set('.section', { clearProps: 'all' })
            .set(outerImageInner, { position: 'absolute' })
            .add(hideHeader())
            .to(
              outerImage,
              {
                top: scrollY,
                left: 0,
                width: window.innerWidth,
                height: window.innerHeight,
                scale: 1,
              },
              '<',
            )
            .to(outerImageInner, { scale: 1 }, '<')
            .set(modalImage, {
              position: 'absolute',
              top: 0,
              left: 0,
              width: window.innerWidth,
              height: window.innerHeight,
              // width: '100vw',
              // height: '100vh',
            })
            .set(modal, { zIndex: 100 })
            .to(modal, { y: 0 })
            .to(modalImage, {
              position: 'absolute',
              top: modalImgTop,
              left: modalImgLeft,
              width: modalImgWidth,
              height: modalImgHeight,
            })
            .add(animateShowCloseButton(), '-=0.5')
            .add(
              animateProgressBar({
                progressBar,
                duration: timeline.duration(),
              }),
              `-=${timeline.duration()}`,
            )
            .set(modalHeader, { zIndex: 50 })
            .set([outerImage, outerImageWrapper, modalImage, modalImageWrapper], {
              clearProps: 'all',
            })
            .then(() => {
              // set clearProps or position is not working for some reason
              outerImageInner.removeAttribute('style');

              // gsap.set(outerImageInner, { scale: 1.2 });
              // gsap.set(outerImageInner, { scale: 1 });
            })
            .then(resolve);

          // GSDevTools.create({ animation: timeline });
        });

      const animateBottomModal = () =>
        new Promise(resolve => {
          // scrollY = isOpenNextModal
          //   ? scrollY
          //   : Math.round(SmoothScroll.scrollY);

          // new SmoothScroll({ scrollContainer });

          if (isTouchScreen) {
            window.scrollTo({ top: scrollY });
            disableBodyScroll(modal);
          }

          const timeline = gsap.timeline({
            defaults: { ease: 'customEase', duration: 1.25 },
            id: 'animateBottomModal',
          });

          timeline
            .set('.section', { clearProps: 'all' })
            .set(modals[prevIndex], isOpenNextModal ? { zIndex: 99 } : {})
            .set(modal, { zIndex: 100, y: '100%' })
            .set(modalImage, {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            })
            .set(modalImageWrapper, {
              width: modalImgWidth,
              height: modalImgHeight,
            })
            .add(hideHeader())
            .to(modal, { y: 0 }, '<')
            .to(modalImage, {
              top: modalImgTop,
              left: modalImgLeft,
              width: modalImgWidth,
              height: modalImgHeight,
            })
            .add(animateShowCloseButton(), '-=0.5')
            .add(
              animateProgressBar({
                progressBar,
                duration: timeline.duration(),
              }),
              `-=${timeline.duration()}`,
            )
            .set(modalHeader, { zIndex: 50 })
            .set([outerImage, outerImageWrapper, modalImage, modalImageWrapper], {
              clearProps: 'all',
            })
            .set(outerImageInner, { scale: 1.2 })
            .then(resolve);

          // GSDevTools.create({ animation: timeline });
        });

      animationTrigger === 'image' && !ScrollTrigger.isScrolling()
        ? await animateImageModal()
        : await animateBottomModal();
    });
  const closeModal = (index: number) => {
    const timeline = gsap.timeline({ defaults: { ease: 'customEase' } });

    const [modal, outerImage, modalImage, modalHeader] = [
      modals[index],
      outerImages[index],
      firstImages[index],
      headers[index],
    ];

    if (isTouchScreen) {
      window.scrollTo({ top: scrollY });

      gsap.set(progressBars[index], { scaleX: 1, overwrite: true });
    }

    timeline
      .set(getInTouchIcon.parentElement, { opacity: 1 })
      .set(header, { y: 0 })
      .set(modalHeader, { zIndex: 0 })
      .to(modal.firstElementChild, { y: '100%', opacity: 0, duration: 1 })
      .to(modal, { opacity: 0, duration: 1 }, '-=1')
      .set(modal, { zIndex: -100 })
      .set([modalImage.parentElement, modalImage, outerImage, modal, modal.firstElementChild], {
        clearProps: 'all',
      })
      .then(() => {
        if (isTouchScreen) enableBodyScroll(modal);
        modal.scrollTo({ top: 0 });

        ScrollTrigger.addEventListener('refresh', () => {
          ScrollTrigger.sort();
        });
      });
  };

  openButtons.forEach(button => {
    button.addEventListener('click', () => {
      animationTrigger = button.getAttribute('data-trigger') as AnimationTrigger;

      const index = modals.findIndex(
        modal => modal.getAttribute('data-case') === button.getAttribute('data-case'),
      );

      openModal({ index });
    });
  });

  nextButtons.forEach(button => {
    const index = modals.findIndex(modal => modal.contains(button));
    button.addEventListener('click', () => openNextModal(index));
  });

  closeButtons.forEach(button => {
    animateCloseButton({ button });

    const index = modals.findIndex(modal => modal.contains(button));

    button.addEventListener('click', () => {
      new SmoothScroll({
        scrollContainer: scrollContainer,
        initPosition: { y: scrollY, x: 0 },
      });

      setTimeout(resetAnimations, 100);

      ScrollTrigger.getAll().forEach(item => item.kill());

      closeModal(index);
    });
  });
};
