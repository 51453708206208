import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

import { Div } from '.';

type AnimateProgressBarProps = {
  progressBar: Div;
  duration: number;
};

export const animateProgressBar = ({ progressBar, duration }: AnimateProgressBarProps) => {
  return gsap
    .timeline({ ease: Power3.easeInOut, id: 'animate-progress-bar' })
    .set(progressBar, { y: 0, scaleX: 0, opacity: 1 })
    .to(progressBar, { scaleX: 1, duration })
    .to(progressBar, { y: '100%', duration: 0.5 })
    .set(progressBar, { scaleX: 0, opacity: 0 });
};
