import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';

import { Div } from '.';

type UpdateCursorPositionProps = {
  cursor: Div;
  x: number;
  y: number;
};

export const updateCursorPosition = ({ cursor, x, y }: UpdateCursorPositionProps) => {
  cursor.style.top = `${y - cursor.clientHeight / 2}px`;
  cursor.style.left = `${x - cursor.clientWidth / 2}px`;
};

type HandleCustomCursorProps = {
  cursor: Div;
  wrapper: Div;
};

export const handleCustomCursor = ({ cursor, wrapper }: HandleCustomCursorProps) => {
  gsap.defaults({ ease: Power3.easeInOut, duration: 0.2 });
  gsap.set(cursor, { scale: 0.5, opacity: 0 });

  const setCursorPosition = (e: MouseEvent) => {
    const { clientX, clientY } = e;

    updateCursorPosition({ cursor, x: clientX, y: clientY });
  };

  const showCursor = () => gsap.to(cursor, { scale: 1, opacity: 1 });
  const hideCursor = () => gsap.to(cursor, { scale: 0.5, opacity: 0 });

  wrapper.addEventListener('mouseenter', e => {
    setCursorPosition(e);

    showCursor();
  });

  wrapper.addEventListener('mouseleave', hideCursor);

  wrapper.addEventListener('mousemove', setCursorPosition);
};
