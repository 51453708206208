// * source: https://codepen.io/GreenSock/pen/eYpGLYL
import { gsap } from 'gsap';
import Expo from 'gsap/CustomEase';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Div } from '.';

class DeformationInfo {
  // public scrollTriggerInstance: gsap.plugins.ScrollTriggerInstance | null =
  //   null;
  public isDeformation = false;
  private subscribers: { [key in 'set' | 'unset']: (() => any)[] } = {
    set: [],
    unset: [],
  };

  on(event: 'set' | 'unset', callback: () => any) {
    this.subscribers[event].push(callback);
  }

  off(event: 'set' | 'unset', callback: () => any) {
    this.subscribers[event] = this.subscribers[event].filter(fn => fn !== callback);
  }

  set deformation(value: boolean) {
    if (value && this.isDeformation !== true) {
      this.subscribers['set'].forEach(callback => callback());
    }

    if (!value && this.isDeformation === true) {
      this.subscribers['unset'].forEach(callback => callback());
    }

    this.isDeformation = value;
  }

  async waitEndOfDeformation() {
    if (!this.isDeformation) return;

    return new Promise<void>(resolve => {
      const handler = () => {
        this.off('unset', handler);
        resolve();
      };

      this.on('unset', handler);
    });
  }
}

export const deformationInfo = new DeformationInfo();

type AnimateScrollDeformationProps = {
  element: string | Div | Element;
  scroller: Div | Element;
  trigger: Div | Element;
  endTrigger?: Div | Element;
};

// TODO: fix modal open animation
export const animateScrollDeformation = ({
  element,
  scroller,
  trigger,
  endTrigger,
}: AnimateScrollDeformationProps) => {
  ScrollTrigger.saveStyles(element);

  const proxy = { skew: 0 };
  const skewSetter = gsap.quickSetter(element, 'skewY', 'deg');
  const clamp = gsap.utils.clamp(-2, 2);

  gsap.set(element, { transformOrigin: 'right center', force3D: true });

  ScrollTrigger.matchMedia({
    '(min-width: 768px)': function () {
      // const instance =
      ScrollTrigger.create({
        scroller,
        trigger,
        endTrigger,
        id: 'animateScrollDeformation',
        start: () => '0% 0%',
        end: () => '100% 100%',
        invalidateOnRefresh: true,
        onUpdate: self => {
          const skew = clamp(self.getVelocity() / 1500);

          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            proxy.skew = skew;

            gsap
              .to(proxy, {
                skew: 0,
                duration: 0.4,
                ease: Expo.out,
                overwrite: true,
                onUpdate: () => {
                  deformationInfo.deformation = true;
                  skewSetter(proxy.skew);
                },
              })
              .then(() => {
                gsap
                  .set(element, { clearProps: 'all' })
                  .then(() => (deformationInfo.deformation = false));
              });
            //.then(() => gsap.set(element, { clearProps: 'all' }));
          }
        },
      });

      // deformationInfo.scrollTriggerInstance = instance;
    },
  });
};
