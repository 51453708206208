import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import { throttle } from 'throttle-debounce-ts';
import { Div } from '.';

export const setContainerPadding = (container: Element, root: HTMLHtmlElement) => {
  const setPadding = () => {
    root.style.setProperty(
      '--container-padding',
      `${(container.firstElementChild as Div).offsetLeft}px`,
    );
  };

  // setPadding();
  // window.onload = () => setPadding();
  // document.addEventListener('DOMContentLoaded', setPadding);
  // window.addEventListener('resize', throttle(100, setPadding));
  ScrollTrigger.addEventListener('refreshInit', setPadding);
};
